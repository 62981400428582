import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { OrganizacaoModel } from '../../_models/organizacao.model';
import { OrganizacaoService } from '../../_services/organizacao.service';
import { AuthService } from '../auth/auth.service';

@Injectable({ providedIn: 'root' })
export class OrganizacaoResolver implements Resolve<OrganizacaoResolver> {

    private organizacao: OrganizacaoModel;

    constructor(
        private authService: AuthService,
        private organizacaoService: OrganizacaoService,
    ) { }

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Promise<any> | any> {
        const id = route.paramMap.get('organizacao');

        this.organizacao = await this.organizacaoService.getById(id);
        if (this.organizacao?.id) {
            this.authService.setOrganizacao(this.organizacao.id);
        }

        return this;
    }


    getOrganizacao(): OrganizacaoModel {
        return this.organizacao;
    }

}
