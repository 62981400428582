import { DAY_OF_WEEK } from "src/app/app.constants";
import { clone } from "../util/clone.fn";
import { GeoCoordinates } from "../util/coordinates";
import { Guid, GuidInterface, GuidKeys } from "../util/guid";
import { Testability } from "@angular/core";

export type LojaHorario = {
    diaSemana: string;
    inicio: string;
    termino: string;
}

export type LojaAreaDeAtendimento = {
    enabled: boolean;
    coordenadas: GeoCoordinates[];
}


export type LojaFrete = {
    preco: number;
    /** Distancia em Metros */
    distancia: number;
    prazo: number;
};

export type LojaAttr = {
    organizacaoId: string;
    accessPoint: string;
    enabled: boolean;
    entregaHabilitado: boolean;
    entregaValorMinimo: number;
    cpfHabilitado: boolean;
    cpfObrigatorio: boolean;
    utilizarEstoque: boolean;
    utilizarFreteValorACombinar: boolean;
    utilizarHorarioDeFuncionamento: boolean;
    horarios: LojaHorario[];
    fretes: LojaFrete[];
    areaDeAtentimento: LojaAreaDeAtendimento;
    retiradaHabilitado: boolean;
    retiradaCusto: number;
    retiradaTempo: string;
    coordenadas: GeoCoordinates;
    enviarPedidoPeloWhats: boolean;
}


export interface LojaInterface extends GuidInterface {
    get id(): string;
    get organizacaoId(): string;
    get accessPoint(): string;
    get enabled(): boolean;

    get retiradaHabilitado(): boolean;
    get retiradaTempo(): string;
    get retiradaCusto(): number;

    get entregaHabilitado(): boolean;
    get entregaValorMinimo(): number;

    get utilizarHorarioDeFuncionamento(): boolean;
    get utilizarEstoque(): boolean;
    get utilizarFreteValorACombinar(): boolean;

    get areaDeAtendimento(): LojaAreaDeAtendimento;
    get horarios(): LojaHorario[];
    get fretes(): LojaFrete[];
    get coordenadas(): GeoCoordinates;
    get cpfHabilitado(): boolean;
    get cpfObrigatorio(): boolean;
    get enviarPedidoPeloWhats(): boolean;

    set areaDeAtendimento(value: LojaAreaDeAtendimento);
    set enabled(value: boolean);
    set entregaHabilitado(value: boolean);
    set entregaValorMinimo(value: number);
    set utilizarHorarioDeFuncionamento(value: boolean);
    set utilizarEstoque(value: boolean);
    set utilizarFreteValorACombinar(value: boolean);
    set retiradaHabilitado(value: boolean);
    set retiradaTempo(value: string);
    set retiradaCusto(value: number);
    set coordenadas(value: GeoCoordinates);
    set cpfHabilitado(value: boolean);
    set cpfObrigatorio(value: boolean);
    set enviarPedidoPeloWhats(value: boolean);


    addFrete(LojaFrete): void;
    delFrete(frete: LojaFrete): void;
    addHorario(horario: LojaHorario): void;
    delHorario(diaSemana: string): void;

    isOpened(date: Date): boolean;

}


export class Loja implements LojaInterface {

    private readonly _attr: LojaAttr;
    private readonly _guid: GuidInterface;

    constructor(attr: LojaAttr, guid: Guid) {
        this._attr = attr;
        this._guid = guid;
    }

    get enviarPedidoPeloWhats(): boolean {
        return this._attr.enviarPedidoPeloWhats ?? true;
    }

    set enviarPedidoPeloWhats(value: boolean) {
        this._attr.enviarPedidoPeloWhats = value ?? true;
    }

    get areaDeAtendimento(): LojaAreaDeAtendimento {
        return this._attr.areaDeAtentimento ?? null;
    }
    set areaDeAtendimento(value: LojaAreaDeAtendimento) {
        this._attr.areaDeAtentimento = value ?? null;
    }

    get cpfHabilitado(): boolean {
        return this._attr.cpfHabilitado;
    }

    set cpfHabilitado(value: boolean) {
        this._attr.cpfHabilitado = value ?? false;
    }

    get cpfObrigatorio(): boolean {
        return this._attr.cpfObrigatorio ?? false;
    }

    set cpfObrigatorio(arg0: boolean) {
        this._attr.cpfObrigatorio = arg0;
    }

    get id(): string {
        return this._guid.id;
    }

    get keys(): GuidKeys {
        return Object.assign({}, this._guid.keys);
    }

    get organizacaoId(): string {
        return this._attr.organizacaoId;
    }

    get accessPoint(): string {
        return this._attr.accessPoint;
    }

    get enabled(): boolean {
        return this._attr.enabled ?? false;
    }

    get entregaHabilitado(): boolean {
        return this._attr.entregaHabilitado;
    }

    get entregaValorMinimo(): number {
        return this._attr.entregaValorMinimo;
    }

    set entregaValorMinimo(value: number) {
        this._attr.entregaValorMinimo = value;
    }

    get utilizarHorarioDeFuncionamento(): boolean {
        return this._attr.utilizarHorarioDeFuncionamento ?? false;
    }

    get horarios(): LojaHorario[] {
        return clone(this._attr.horarios ?? []);
    }

    get fretes(): LojaFrete[] {
        return clone(this._attr.fretes ?? [])
    }

    get retiradaHabilitado(): boolean {
        return this._attr.retiradaHabilitado;
    }
    get retiradaCusto(): number {
        return this._attr.retiradaCusto;
    }

    get retiradaTempo(): string {
        return this._attr.retiradaTempo;
    }

    get coordenadas(): GeoCoordinates {
        return clone(this._attr.coordenadas);
    }

    get utilizarEstoque(): boolean {
        return this._attr.utilizarEstoque ?? false;
    }

    set utilizarEstoque(value: boolean) {
        this._attr.utilizarEstoque = value ?? false;
    }

    set enabled(value: boolean) {
        this._attr.enabled = value ?? false;
    }

    set entregaHabilitado(value: boolean) {
        this._attr.entregaHabilitado = value;
    }

    set utilizarHorarioDeFuncionamento(value: boolean) {
        this._attr.utilizarHorarioDeFuncionamento = value;
    }

    set retiradaHabilitado(value: boolean) {
        this._attr.retiradaHabilitado = value;
    }

    set retiradaTempo(value: string) {
        this._attr.retiradaTempo = value;
    }

    set retiradaCusto(value: number) {
        this._attr.retiradaCusto = value;
    }

    set coordenadas(value: GeoCoordinates) {
        this._attr.coordenadas = clone(value);
    }

    get utilizarFreteValorACombinar(): boolean {
        return this._attr.utilizarFreteValorACombinar;
    }

    set utilizarFreteValorACombinar(value: boolean) {
        this._attr.utilizarFreteValorACombinar = value;
    }

    addFrete(frete: LojaFrete): void {
        this._attr.fretes.add(frete, f => Object.keys(f).every(k => f[k] === frete[k]));
    }

    delFrete(frete: LojaFrete): void {
        this._attr.fretes.del(f => Object.keys(f).every(k => f[k] === frete[k]));
    }

    addHorario(horario: LojaHorario): void {
        this._attr.horarios.add(horario, h => h.diaSemana === horario.diaSemana);
    }

    delHorario(diaSemana: string): void {
        this._attr.horarios.del(h => h.diaSemana === diaSemana)
    }

    isOpened(date: Date): boolean {
        if (!this.enabled)
            return false;

        if (!this.utilizarHorarioDeFuncionamento) {
            return true;
        }

        const d = date.getDay();
        const h = date.getHours();
        const m = date.getMinutes();
        const t1 = h * 60 + m;

        const t2n = (time: string): number => {
            if (!time) { return 0; }
            const s = time.split(':').map(a => +a);
            return s[0] * 60 + s[1];
        }

        const result = this.horarios
            .filter(a => a.diaSemana === DAY_OF_WEEK[d].type)
            .some(a => t1 >= t2n(a.inicio) && t1 <= t2n(a.termino));

        return result;
    }
}
