<div>
    <button class='button-remove' mat-icon-button (click)="dec()" title="remover"
        [disabled]='size<=0 || size<=minSize && !zerable || disabled'>
        <mat-icon>remove</mat-icon>
    </button>
    <label>{{size}}{{sufix}}</label>
    <button class='button-add' mat-icon-button (click)="inc()" title="adicionar"
    [disabled]='(size + stepSize) > maxSize || disabled'>
        <mat-icon>add</mat-icon>
    </button>
</div>
