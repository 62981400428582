<div class="carousel relative">
    <div class="carousel-content scroll-smooth hover:scroll-auto">
        <ng-content select="[slide]"></ng-content>

    </div>
    <div
        class="carousel-navigation"
        touch
        (left)="onLeftGotoNext($event)"
        (right)="onRightGotoPrevious($event)"
    >
        <div
            class="left"
            (click)="onClickGotoPrevious($event)"
        ></div>
        <div
            class="right"
            (click)="onClickGotoNext($event)"
        ></div>
        <button
            *ngIf="showAspectRadioButton"
            class="btn-photo"
            mat-icon-button
            (click)="openAspectRadio()"
            style=""
        ><mat-icon>aspect_ratio</mat-icon></button>
    </div>
    <div
        class="carousel-indicators items-center mt-1"
        *ngIf="slides.length>1"
        (click)='$event.stopPropagation()'
    >
        <div class='flex flex-auto w-min gap-2 mx-auto items-center'>
            <ng-container *ngFor="let s of slides;let i=index;">
                <div
                    class="carousel-indicator"
                    [ngClass]="{selected: i===currentIndex}"
                    (click)="onClickGotoSlide($event,i)"
                ></div>

            </ng-container>
        </div>
    </div>
</div>
