import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'carousel-image-modal',
    template: `
    <div class="modal-container">
      <img [src]="data.imageUrl" class="full-image">
    </div>
    <button class='mat-focus-indicator mat-raised-button mat-button-base mat-orange w-32 !mt-6'  mat-raised-button color='orange' (click)="dialogRef.close()" >Fechar</button>
  `,
    styles: [`
    .modal-container { all: unset; display: block; max-width: 100vw; max-height: 100vh; overflow: auto; margin:-24px  }
    .full-image { all: unset;  }
    .mat-raised-button { position: fixed; bottom: 1rem; left: calc(50vw - 4rem); }
  `]
})
export class ImageModalComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { imageUrl: string },
        public dialogRef: MatDialogRef<ImageModalComponent>
    ) { }
}
