import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, NgModule, OnInit, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
    selector: 'ui-increment-button',
    templateUrl: './increment-button.component.html',
    styleUrls: ['./increment-button.component.scss']
})
export class IncrementButtonComponent implements OnInit {

    @Input('zerable') zerable = false;
    @Input('step') stepSize = 1;
    @Input('min') minSize = 0;
    @Input('max') maxSize = 999;
    @Input('size') size = 0;
    @Input('sufix') sufix;
    @Input('disabled') disabled = false;

    @Output('sizeChange') sizeChange = new EventEmitter<number>();


    constructor() { }

    ngOnInit(): void { this.resize(0); }

    dec(): void { this.resize(-this.stepSize); }
    inc(): void { this.resize(+this.stepSize); }

    resize(delta: number) {
        console.log('this.maxSize', this.maxSize);  // Log para depuração

        const newSize = +this.size + delta;
        if (this.zerable && delta <= 0 && newSize < this.minSize) {
            this.size = 0;
        } else {
            // Verificação explícita para garantir que o maxSize seja respeitado
            if (newSize > this.maxSize) {
                this.size = this.maxSize;
            } else if (newSize < this.minSize) {
                this.size = this.minSize;
            } else {
                this.size = newSize;
            }
        }

        this.size = Math.round10(this.size, -2);  // Se a função round10 for realmente necessária
        this.sizeChange.emit(this.size);
    }

}

@NgModule({
    declarations: [IncrementButtonComponent],
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
    ],
    exports: [IncrementButtonComponent],
})
export class IncrementButtonModule { }
