import { AfterContentInit, AfterViewInit, Component, ElementRef, Input, OnChanges, OnDestroy } from '@angular/core';
import { Subject, Unsubscribable } from 'rxjs';
import { ModalButton } from '../modal/modal.service';
import { ModalService } from '@azoup/ngx-ui';
import { MatDialog } from '@angular/material/dialog';
import { ImageModalComponent } from './image-modal.component';

@Component({
    selector: 'ui-carousel',
    templateUrl: './carousel.component.html',
    styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnDestroy, OnChanges, AfterViewInit, AfterContentInit {

    slides: any[] = []
    currentIndex = 0;

    @Input('showAspectRadioButton')
    showAspectRadioButton = false;

    private _unsubscribeAll = new Subject<void>();
    private _unsubscribeInterval: Unsubscribable;
    private _content: any;

    constructor(
        private _hostElementRef: ElementRef,
        private _matDialog: MatDialog
    ) { }

    ngOnChanges(): void {
    }

    ngAfterContentInit(): void {
        this._content = this.getContent();
        this.slides = this.getSlides();
        this.slides.map((s: HTMLElement) => {
            s.className += " image-container ";
        });

    }

    ngAfterViewInit(): void {
        this.gotoSlide(0);
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }


    /**
     * Events
     */
    onClickGotoSlide(event: MouseEvent, index: number) {
        if (index === this.currentIndex)
            return;

        this.gotoSlide(index);

    }

    onClickGotoNext(event: MouseEvent) {
        this.gotoNextSlide();
        this.stopImmediatePropagation(event);
    }


    onClickGotoPrevious(event: MouseEvent) {
        this.gotoPreviousSlide();
        this.stopImmediatePropagation(event)
    }

    onRightGotoPrevious(events: TouchEvent[]) {
        this.gotoPreviousSlide();
        this.stopImmediatePropagation(...events);
    }

    onLeftGotoNext(events: TouchEvent[]) {
        this.gotoNextSlide();
        this.stopImmediatePropagation(...events);
    }

    openAspectRadio() {
        const image = this.slides[this.currentIndex] as (HTMLImageElement);
        const url = image.getAttribute('original-photo');
        this._matDialog.open(ImageModalComponent, {
            data: { imageUrl: url },
            minHeight: '100vh',
            minWidth: '100vw'
        });
    }


    /**
     * Private methods
     */
    private getSlides(): any[] {
        return [...this._hostElementRef.nativeElement.querySelectorAll('[slide]')];
    }

    private getContent(): any {
        return this._hostElementRef.nativeElement.querySelectorAll('.carousel-content')[0];
    }


    private stopImmediatePropagation(...events: (TouchEvent | MouseEvent)[]) {
        if (this.slides.length > 1) {
            events.map(event => {
                event.stopImmediatePropagation();
                event.stopPropagation();
            });
        }
    }

    private gotoNextSlide(): void {
        if (this.slides.isEmpty())
            return;

        if (this.currentIndex + 1 >= this.slides.length)
            return;

        this.gotoSlide(this.currentIndex + 1);
        this._unsubscribeInterval?.unsubscribe();
    }


    private gotoPreviousSlide(): void {
        if (this.slides.isEmpty())
            return;

        if (this.currentIndex - 1 <= -1)
            return;

        this.gotoSlide(this.currentIndex - 1);
        this._unsubscribeInterval?.unsubscribe()
    }


    private gotoSlide(index: number): void {
        this.showAspectRadioButton = false;

        if (!this.slides[index])
            throw new Error('index out of length')

        const x = this.slides.filter((a, i) => i <= index - 1)
            .reduce((a, b) => a += b.width ?? 0, 0);

        const y = 0;
        this._content.scrollTo(x, y);
        this.currentIndex = index;

        const image = this.slides[this.currentIndex] as (HTMLImageElement);
        const url = image.getAttribute('original-photo');
        this.showAspectRadioButton = !!url;
    }


}
